.list {
  width: 100%;
  padding: 0;

  @media screen and (max-width: 450px) {
    width: 120%;
  }
}

.item,
.phone {
  flex: 1 !important;
}

.address {
  @media screen and (max-width: 850px) {
    text-align: right;
  }
}

.highlight {
  background-color: rgb(232, 232, 232);
  padding: 2px 6px;
  font-weight: bold;
  border-radius: 4px;
  margin-right: 5px;
}

.qrCodeContainer {
  position: relative;
  align-items: center;
  margin-top: 120px; // for print alignment
  text-align: center;
  padding: 5px;
  // all text in qr code info (phone number, date etc.)
  & > * {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.8rem;

    // titles for data (ex: Wash Type: Premium)
    span {
      font-weight: bold;
    }
  }
}

.notes {
  max-width: 35%;
  font-weight: normal !important;
}
// item tag
.itemTagWrp {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 40% 40% 10% 10%;
}

.tagsList {
  border: 2px solid #000;
}
.tagsList > p {
  padding: 0;
  margin: 0;
  padding: 10px 0;
  text-align: center;
  border-bottom: 2px solid #000;
}
// unknow item form
.unknownItemForm {
  display: flex;
  flex-direction: column;
}
// tag to print
.tagContent {
  border: 2px solid #000;
  border-radius: 4px;
}
//   color: #92bcb2;
