// Ask the team if they like this for style which tab you are on.
// Might be cleaner than the name
// .btn{
//     background: white;
// }
// .btn:focus{
//     background: gray;
//     border-bottom: 1px, solid, magenta;

// }

button:hover,
button.active {
  background-color: lightgray;
  color: black;
}
