.textWidth {
  max-width: 100%;
  min-width: 100%;
}

.formDropDown {
  cursor: pointer;
  color: green;
}

.dottedLine {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px;
  border-style: dashed;
}
